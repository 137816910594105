<template>
  <layout :title="$route.name">
    <Form class="pa-3" />
    <!-- <MainAddress class="pa-3" v-if="tabs === 1" /> -->
    <MainAccountBank class="pa-3" v-if="tabs === 1" />
    <ModalViewAddress />
  </layout>
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./components/Form";
import MainAddress from "@/apps/9#addresses/Main";
import MainAccountBank from "./components/MainAccountBank";
import ModalViewAddress from "@/apps/9#addresses/components/ModalViewAddress";
export default {
  data() {
    return {
      tabs: 0
    };
  },
  components: {
    Form,
    MainAddress,
    ModalViewAddress,
    MainAccountBank
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  }
};
</script>
